@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Encode+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=JetBrains+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap");

:root {
	--sansEncode: "Encode Sans", "sans-serif";
	--font-sans: "Fira Sans", sans-serif;
	--font-mono: "JetBrains Mono", monospace;

	--primary: #d55107;
	--textWhite: #f9f9f9;
	--gray1: #9d9ca4;
	--gray2: #7f7f8f;
	--gray3: #f3f2f2;
	--gray4: #e5e5e5;
	--purple1: #1b1824;
	--purple2: #504e5a;
	--purple4: #a29fa8;
	--purple5: #d1cccd;
	--black: #1b1824;
	--white-1: #ffffff;
	--white-2: #f8f8f8;
}

.post h1 {
	@apply font-sansEncode font-semibold text-[40px] text-purple1;
}

.post h2 {
	@apply font-sansEncode font-medium md:text-[32px] leading-10 text-2xl text-purple1;
}

.post p {
	@apply font-sansEncode text-base font-light text-purple1;
}

.post a {
	@apply text-base text-primary;
}

.post a span {
	@apply text-xl text-primary;
}

.post a img {
	@apply w-6 h-6;
}

.post ul {
	@apply pl-6 list-disc list-outside text-base text-purple1 font-light font-sansEncode leading-6;
}

.post strong {
	@apply text-purple1 font-bold font-sansEncode;
}

.post img {
	@apply w-full object-cover h-[873];
}

.menuBg {
	background-color: rgba(27, 24, 36, 0.5);
}

.menuBtnBg {
	background-color: rgba(27, 24, 36, 0.5);
	color: #d55107;
}

.hidden {
	display: none;
}

.mobile {
	display: none;
}

/* CAROUSEL */

.items {
	position: absolute;
	user-select: none;
	right: 65%;
	margin-top: 8rem;
}

.items .item {
	position: absolute;
	transform: translate(-50%, 0%);
	width: 300px;
	height: 300px;
	z-index: -1;
	opacity: 0;
}

.item.active {
	opacity: 1;
	z-index: 99;
	padding-bottom: 23.1rem;
}

.item.prev {
	z-index: 2;
	opacity: 0.25;
	transform: translate(-170%, 0%);
}

.item.next {
	z-index: 2;
	opacity: 0.25;
	clip: 20px;
	transform: translate(75%, 0%);
	clip: rect(0px, 64px, 300px, 0px);
}
.progress-container {
	height: 1.5px;
	width: 32rem;
	border-radius: 0.4rem;

	background: #d1cccd;
  }

.progress-container .progress {
	height: 3px;
	width: 25%;
	border-radius: 0.4rem;
	background: #1b1824;
	transition: width 0.4s ease;
  }

@media (max-width: 760px) {
	#menuId {
		display: none;
		width: 100vw;
		min-height: 100vh;
		position: fixed;
		top: 0;
	}

	.mobile {
		display: block;
	}

	#menuBtnId {
		position: fixed;
		top: 54px;
		left: 40px;
	}

	#menuBtnClose {
		position: fixed;
		top: 54px;
		left: 40px;
		width: 20px;
		height: 20px;
	}

	.mobileStyle {
		position: inherit;
		margin-left: 20px;
	}

	.selected {
		color: #d55107 !important;
	}

	.arrowIcon {
		width: 17px;
		display: none;
	}

	.post h1 {
		@apply font-sansEncode font-semibold text-2xl lowercase text-purple1;
	}

	.post h2 {
		@apply font-sansEncode font-medium text-[32px] lowercase text-purple1;
	}

	.post a span {
		@apply text-base text-primary;
	}

	.post img {
		@apply h-[234px] object-cover;
	}

	.post a img {
		@apply w-4 h-4;
	}
}
